import React from 'react'
import './ServiceHead.css'

const ServiceHead = () => {
    return (
        <div className='servicehead container'>
            <div className="servicehead-text">
                <h1>What Services do we have?</h1>
                <p>
                    we provide customize service, and paint coating and maintenance service</p>
            </div>
        </div>
    )
}

export default ServiceHead
